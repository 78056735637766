const yewno = 'https://imageshack.com/a/img921/4950/MqehZi.png'
const chime = 'https://imagizer.imageshack.com/img924/2464/fb6QoI.png'
const vivelia = 'https://imageshack.com/a/img924/3828/qams33.png'
const expertLead = 'https://imageshack.com/a/img922/337/1P2Sdr.png'
const goody = 'https://imageshack.com/a/img924/8728/CXylNn.png'
const beyt = 'https://imageshack.com/a/img922/3042/btzBb9.png'
const bizbook = 'https://imageshack.com/a/img922/7499/JQMrU8.png'
const edu720 = 'https://imageshack.com/a/img921/7852/esVi54.png'
const pravopis = 'https://imageshack.com/a/img923/1039/XlO59t.png'
export const projects= [
    {
        "firstSubtitle": "Website",
        "firstTitle": "YEWNO",
        "firstText": `Yewno’s mission is that of extracting knowledge from an overwhelming quantity of unstructured and structured data. Their technology helps to overcome the “Information Overload” problem and to research and to understand the world in a more natural manner.It is inspired by the way humans process information from multiple sensorial channels and it leverages state-of-the-art Computational Linguistics, Network Theory, Machine Learning, as well as methods from the classical Artificial Intelligence. At the core of their echnology is the framework that extracts, processes, links and represents atomic units of knowledge – concepts – from heterogeneous data sources.`,
        "mainTitle": "Fantastic Mobile Design, Profound Business Analysis,and a ‘Wow’ Factor for Your App",
        "secondTitle": "Chime Solutions",
        "secondSubtitle": "website",
        "secondText": `Chime Solutions delivers a range of customer contact solutions to take care of your customers
        We helped our client Chime Solutions to develop a series of solutions ranging from an Intranet Web Application that provides variety of tools and applications for employee management and resource planning, hourly overview and management intranet solution, to an interactive mobile application for their employees. Intranet application helps day to day operations in a company that provides customer contact services in a wide range of industries.`,
        "firstImage": yewno,
        "secondImage": chime,
        "textOrientation": "right",
    },
    {
        "firstTitle": "Vivelia Psychoterapie",
        "firstSubtitle": "website",
        "firstText": `Coaching for better work accompanies people, teams and companies since 2014 in recognising their inner attitude, values and resources. Vivelia thus creates a sustainable change in corporate culture towards more personal responsibility, productivity and satisfaction. Their coaching concept starts in companies where value is created. With employees, at eye level and scalable.`,
        "mainTitle": "Take your business to a new level. By incorporating the newest UI and UX trends - take your game to a higher level",
        "secondTitle": "Expert Lead",
        "secondSubtitle": "Website",
        "secondText": "Expert Lead is a German service that offers freelancers or teams for all your software needs. What distinguishes Expert Lead from other similar sites is that they offer a full time person that will assist you throughout the project from start ‘till the delivery. It was a blast working with these guys in creating their new website to be used, and we wish them all the best in the future. ",
        "firstImage": vivelia,
        "secondImage": expertLead ,
        "textOrientation": "left",
    },
    {
        "firstTitle": "Goody.ba",
        "firstSubtitle": "MOBILE APP",
        "firstText": "Goody is a free mobile application that offers you a chance to win great prizes in categories: Gaming, Fashion, Food, Beauty, Lifestyle, Electronics etc. Scratch three identical thumbnails to win! Did you find your goody? Great! Click it and start scratching - the goal is to get three of the same screenshots!",
        "mainTitle": "Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live.",
        "secondSubtitle": "mobile app",
        "secondTitle": "Beyt",
        "secondText": `Baatee or Beyt is a AirBnB type of application aimed at the middle east countries. What differentiates this application from other of this type (sale and rent of property) is the ease to use UI, properties tailored for your search. Take a look at this application and find your next property. Happy "hunting"!`,
        "firstImage": goody,
        "secondImage": beyt,
        "textOrientation": "right",
    },
    {
        "firstSubtitle": "",
        "firstTitle": "",
        "firstText": "",
        "mainTitle": "If at first you don’t succeed; call it version 1.0",
        "secondTitle": "Bizbook.ba",
        "secondSubtitle": "Website",
        "secondText": `Bizbook.ba is an online platform that seeks to connect lower and middle level companies together for easier cooperation. Companies that offer amazing products but with lack of huge marketing investments are often overlooked by the "bigger fishes". Bizbook solves the problem by offering a product for your needs, no needless hassle.`,
        "firstImage": null,
        "secondImage": bizbook,
        "textOrientation": "left",
    },
     {
        "firstTitle": "Edu720",
        "firstSubtitle": "Mobile app and Website",
        "firstText": `EDU720 is perfect for busy, distracted 21st century learners. It lessens learner's cognitive load with short, engaging videos and interactive tests by using the nanolearning methodology. It’s not just about learning per se. For many companies, bite-sized learning is about getting maximum benefits through minimal input. It’s about developing short yet relevant chunks of information based on the needs of learners and of the business model. This approach puts the Pareto (or 80/20) Principle to good use. It’s the vital 20% that’s responsible for 80% of your positive eLearning outcomes. Thus, costs are reduced and the return on investment increases. Edu720 lessens the user’s cognitive load with short, engaging videos and interactive tests. Designed for demanding and dynamic organization, it fulfills the need of continuous measurement, reporting and behavior tracking on how do employees learn and grow.`,
        "mainTitle": "Measuring programming progress by lines of code is like measuring aircraft building progress by weight.",
        "secondTitle": "Pravopis.ba",
        "secondSubtitle": "MOBILE APP",
        "secondText": "Pravopis is your own grammar and dictionary in your pocket in a form of a mobile application. After 22 years, a new grammar and dictionary for the Bosnian language has been released and we are more than proud of saying that we have been a part of this.",
        "firstImage": edu720,
        "secondImage": pravopis,
        "textOrientation": "left",
    }
]
