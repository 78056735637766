import React from 'react';

const cloud1 = 'https://imagizer.imageshack.com/img921/7453/zrxzcT.png'
const cloud2 = 'https://imagizer.imageshack.com/img924/5545/LRM8BR.png'
const cloud3 = 'https://imagizer.imageshack.com/img924/2574/qogNjo.png'

const Landing_header= () => {
  let networks = '//imagizer.imageshack.us/v2/6712x2470q90/r/924/HCuvcI.png';
  var date = new Date();
  var month = date.getMonth() + 1;
  switch (month) {
  case 12:
  case 1:
  case 2:
    networks = '//imagizer.imageshack.us/v2/6712x2470q90/r/924/HCuvcI.png';
    break;
  case 3:
  case 4:
  case 5:
    networks = '//imagizer.imageshack.com/img921/2769/tUTVs1.png';
    break;
  case 6:
  case 7:
  case 8:
    networks = '//imagizer.imageshack.com/img921/2769/tUTVs1.png';
    break;
  case 9:
  case 10:
  case 11:
    networks = '//imagizer.imageshack.com/img921/2769/tUTVs1.png';
    break;
  default:
    networks = '//imagizer.imageshack.com/img921/2769/tUTVs1.png';
    break;
}

  return (
  <div className="landing-header--wrapper" style={{ background: `url(${networks}) center center / cover no-repeat` }}>
    <div id="background-wrap">
      <div className="x1">
          <img src={cloud1} alt="clouds AntColony" />
      </div>

      <div className="x2">
          <img src={cloud2} alt="clouds AntColony" />
      </div>

      <div className="x3">
          <img src={cloud3} alt="clouds AntColony" />
      </div>

      <div className="x4">
          <img src={cloud1} alt="clouds AntColony" />
      </div>

      <div className="x5">
          <img src={cloud3} alt="clouds AntColony" />
      </div>

      <div className="x6">
          <img src={cloud2} alt="clouds AntColony" />
      </div>
    </div>
    <h1 className="landing-header--text">Committed in growing your business</h1>
  </div>
)}

export default Landing_header;
