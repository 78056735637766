import React from 'react';
import JobForYou from './JobForYou'
import JobPosition from './JobPositions'
import { jobs } from './JobPositions/jobs'

const jumpToTop = 'https://imagizer.imageshack.com/img922/7472/Z0KYeh.png'

const Jobs = () => {

  const scrollToTop = () =>{
    window.scroll({top: 0, left: 0, behavior: 'auto' })
  }

  return(
  <div className="jobs">
    <JobForYou />
    <div className="container">
    {jobs.map(job => (
            <JobPosition className="job" key={job.positionTitle} subtitle={job.subtitle} positonTitle={job.positionTitle} text={job.text}  />
          ))}
    </div>
    <div className="container jump-to-top">
        <div className="jump-to-top--container">
            <img src={jumpToTop} alt="jump to top "  onClick={scrollToTop}/>
            <h5>JUMP TO TOP</h5>
        </div>
    </div>
  </div>
  )
}
export default Jobs;