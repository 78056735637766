import React from 'react';
import { Link } from 'react-router-dom';

const pic = 'https://imagizer.imageshack.com/img923/715/Fu3eBQ.png'

const BeyondCoding= () => (
  <div className="beyond-coding">
    <div className="container">
        <h1>
            We go way beyond coding
        </h1>
        <div className="container px-0">
            <div className="one-row">
                <div className="col-12 col-md-6 beyond-coding--column">
                    <h2 className="beyond-coding--number pb-3 pt-md-3">1</h2>
                    <h2>Client managed model</h2>
                    <p className="pr-md-5">
                    Dedicated developers and QA resources (Client managed or
                    Ant Colony managed resource). In this model our developers
                    can work under your direction
                    </p>
                    <Link to="/contact">
                        <button className="btn beyond-coding--button">HIRE US</button>
                    </Link>
                </div>
                <div className="col-12 col-md-6 beyond-coding--column ">
                    <img src={pic} alt="antcolony client managed model" />
                </div>
                <div className="client-model-row pt-3">
                    <div className="col-12 col-md-6 beyond-coding--column">
                        <h2 className="beyond-coding--number pb-3 ">2</h2>
                        <h2>We lead on your behalf</h2>
                        <p className="pr-md-5">
                        We can direct them on your behalf with a project manager and
                        adequate scale of team members engaged in the team. Client
                        provides infrastructure, software architecture and product/process ownership
                        </p>
                    </div>
                    <div className="col-12 col-md-6 beyond-coding--column">
                        <h2 className="beyond-coding--number pb-3">3</h2>
                        <h2>Flexibility model</h2>
                        <p className="pr-md-5">
                        Dedicated team(s) and development services. This model offers reliable delivery of solutions and scalable flexible services in collaboration with the client
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
)

export default BeyondCoding;