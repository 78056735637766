import React from 'react';
import Tilt from 'react-tilt'

const pic = 'https://imagizer.imageshack.com/img922/3426/mT3hO4.png';

const OurTradition = () => {
    return (
        <div className="our-tradition">
            <div className="container">
                <h1>We cherish our traditions</h1>
                <p className="p-header">Pizza Tuesday, Party Fridays, Workshops and many many more traditions that we <br /> really hold dearly.</p>
                <div className="container">
                <Tilt className="Tilt" options={{ max : 35 }} >
                    <div className="Tilt-inner">
                     <img src={pic} alt="antcolony tradition ant" />
                    </div>
                </Tilt>
                </div>
            </div>
        </div>
    )
}

export default OurTradition;