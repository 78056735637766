const HRocket = 'https://imagizer.imageshack.com/img924/5778/lY4lIJ.png'
const radiBa = 'https://imagizer.imageshack.com/img921/7444/lsq5cm.png'
const healtApp = 'https://imageshack.com/a/img924/9713/NNDlJX.png'
const washVesh = 'https://imagizer.imageshack.com/img924/7742/tZyVbB.png'
const opcine = 'https://imagizer.imageshack.com/img924/6186/FnTX58.png'
const cultureHiking = 'https://imageshack.com/a/img923/2734/nTwObw.png'

export const casestudies=[
    {
        "firstTitle": "HRocket",
        "firstSubtitle": "MOBILE & WEB APP",
        "firstText": `HRocket is an AI driven recruitment tool that helps recruiters scout and hire the best possible candidates.  Using advanced machine learning algorithms HRocket will present you candidates that, by assessing the soft skill and cognitive scores, could be the best fit for the applied role. Welcome to the future of recruitment!`,
        "mainTitle": "A good programmer is someone who always looks both ways before crossing a one-way street.",
        "secondTitle": "Radi.ba",
        "secondSubtitle": "MOBILE app",
        "secondText": "Radi.ba is an application that aims to help people find seasonal work, or help you find a handyman for your needs. With a easy UX and pleasing UI - it aims to offer you instant choices, be it a nanny, handyman, translator etc.",
        "firstImage": HRocket,
        "secondImage": radiBa,
        "textOrientation": "right",
    },{
        "firstTitle": "Health",
        "firstSubtitle": "MOBILE APP",
        "firstText": "Concept application that won the UNIQA APPlause award for most innovative application. Not just made as a classic healthy habit tracker but as a life coach with a AI that learns about you and suggests the best for you. A friend in your pocket - stay healthy and achieve positive habits.",
        "mainTitle": "Show your app to the world with cutting edge design and great technical implementation. Contact us and share your idea, we will make it into a reality",
        "secondTitle": "WashVes",
        "secondSubtitle": "MOBILE APP",
        "secondText": `Wash Ves is a mobile application that eases the annoyance of washing your clothes, be it normal or dry cleaning. Choose the things that need to be cleaned, decide on a pickup time and date and we will take care of everything else. That is the motto of WashVes`,
        "firstImage": healtApp,
        "secondImage": washVesh,
        "textOrientation": "left",
    },
    {
        "firstTitle": "Ocijeni svoju Opcinu",
        "firstSubtitle": "MOBILE APP",
        "firstText": `A small easy to use application aimed for everyone that uses public services. Once finished you can rate the service received and of course browse other public places to see how they fare against each other.`,
        "secondTitle": "Culture Hiking",
        "secondSubtitle": "website",
        "secondText": `Website for a tourism agency that wanted to do purely culture destinations in goal to broaden the positive and traditional places to see in Bosnia and Herzegovina. Browse through a big selection of tours and sightseeing opportunities in Bosnia and Herzegovina and get ready to be amazed by all the natural beauty this country has to offer. Cultural Hiking offers package deals for big groups as well as individuals.`,
        "mainTitle": "The best thing about a boolean is even if you are wrong, you are only off by a bit",
        "firstImage": opcine,
        "secondImage": cultureHiking,
        "textOrientation": "right",
    },
]
