import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from 'react-ga'
// import auth from './auth.ts'; // Sample authentication provider

import RoutesContainer from './RoutesContainer';

import './App.css';

const trackingId = "UA-150360644-1'";
ReactGA.initialize(trackingId);
ReactGA.pageview('/Home');
//   ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

const App = () => {

  return (
    <div className="App">
      <Router>
        <RoutesContainer />
      </Router>
    </div>
  );
}

export default App;
