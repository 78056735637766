import React from 'react';

import OurEngagementModel from './OurEngagementModel'
import BeyondCoding from './BeyondCoding'
import SetOfSkills from './SetOfSkills'
import WelcomeToOurHome from './WelcomeToOurHome'

const jumpToTop = 'https://imagizer.imageshack.com/img922/7472/Z0KYeh.png'

const EngagementModel = () => {
  const scrollToTop = () =>{
    window.scroll({top: 0, left: 0, behavior: 'auto' })
  }

  return(
    <div className="how-we-work">
      <OurEngagementModel />
      <BeyondCoding />
      <SetOfSkills />
      <WelcomeToOurHome />
      <div className="container jump-to-top" >
          <div className="jump-to-top--container">
              <img src={jumpToTop} alt="jump to top "  onClick={scrollToTop}/>
              <h5>JUMP TO TOP</h5>
          </div>
      </div>
    </div>
)}

export default EngagementModel;