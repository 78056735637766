import React, { useRef, useEffect, useState} from 'react'

const ProjectItem = (props) =>{
    const ref = useRef(null)
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        };
        const containerWidth = ref.current ? ref.current.offsetWidth : 0;
        const margin = ((width-containerWidth)/2)
        window.addEventListener('resize', handleResize);
        const rightElements = document.getElementsByClassName('right')
        applyStyleToElement(rightElements, 'paddingRight',  margin)
        const leftElements = document.getElementsByClassName('left')
        applyStyleToElement(leftElements, 'paddingLeft',  margin)
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, [width]);

    const applyStyleToElement = (elements, path, size) => {
        for (let i=0; i < elements.length; i++){
            elements[i].style[`${path}`] = `${size}px`
        }
     }

    return (
        <div className={`${props.className}`}>
            { props.firstTitle && (
                <div>
                    <img src={props.firstImage}  alt="our projects done, AntColony"/>
                    <div className="container first-part py-md-5" ref={ref}>
                        <div className="d-flex">
                            <div className="Oval"></div>
                            <h5 className="subtitle">{props.firstSubtitle}</h5>
                        </div>
                        <h3>{props.firstTitle}</h3>
                        <div className="first-text-container mr-md-5">
                            <p>{props.firstText}</p>
                        </div>
                    </div>
                </div>
                )
            }
            {
                props.secondTitle && (
                    <div className={`second-part my-md-5 ${props.textOrientation}`}>
                        <div  className="second-part-img col-12 col-md-6">
                            <img src={props.secondImage} alt="our projects done, AntColony" />
                        </div>
                        <div className={`col-12 col-md-6 second-part-text ${props.textOrientation==='right' ? 'left-text' : ''}`}>
                            <h2 className="pb-md-5">{props.mainTitle}</h2>
                            <div className="d-flex">
                                <div className="Oval"></div>
                                <h5 className="subtitle">{props.secondSubtitle}</h5>
                            </div>
                            <h3 className="pb-md-5">{props.secondTitle}</h3>
                            <div className="second-text-container pr-md-5">
                                <p>{props.secondText}</p>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>

    )
}

export default ProjectItem;