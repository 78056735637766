import React from 'react';
import { Link } from 'react-router-dom';
import Tilt from 'react-tilt'

const pic1 = 'https://imagizer.imageshack.com/img922/1835/rAq17Z.png';

const Fun = () => (
  <div className=" fun">
    <div className="container">
      <h1>F.U.N</h1>
      <p>People to meet and places to see, we love doing stuff together.</p>
      <Link to="/team">
        <button className="fun-button">OUR TEAM</button>
      </Link>
      <div className="row">
          <div className="col-12 col-md-6">
          <Tilt className="Tilt" options={{ max : 35 , scale:1}} >
              <div className="Tilt-inner">
                <img src={pic1} alt="Snowboarding" />
              </div>
          </Tilt>
          </div>
          <div className="col-12 col-md-6 fun-row">
              <h2>We are result driven</h2>
              <p className="pr-md-5">
              In a complex world that's always demanding more,  Ant Colony is focused on creating complex and result-driven solutions.
              <br />
              Allow us to take your business and company to the next level.
              </p>
          </div>
      </div>
    </div>
  </div>
)

export default Fun;