import React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import SiteNavbar from './Navbar';
import Footer from './Footer';
import Home from './Home';
import EngagementModel from './EngagementModel';
import Team from './Team';
import Jobs from './Jobs';
import Contact from './Contact';
import Projects from './Projects';
import Gallery from './Gallery';

import ScrollToTop from './ScrollToTop';

const RoutesContainer = (props) => (
  <div className="app--wrapper">
    <TransitionGroup className="transition-group">
      <CSSTransition
        key={props.location.key}
        classNames={"fade"}
        timeout={400}
      >
        <div className="route-section">
          <SiteNavbar />
          <ScrollToTop />
          <Switch location={props.location}>
            <Route path="/" exact component={Home} />
            <Route path="/team" component={Team} />
            <Route path="/how-we-work" component={EngagementModel} />
            <Route path="/jobs" component={Jobs} />
            <Route path="/contact" component={Contact} />
            {/* <Route path="/testimonials" component={Testimonails} /> */}
            <Route path="/projects" component={Projects} />
            <Route path="/gallery" component={Gallery} />
          </Switch>
          <Footer />
        </div>
      </CSSTransition>
    </TransitionGroup>
  </div>
);

export default withRouter(RoutesContainer);
