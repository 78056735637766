import React from 'react';

const pic = 'https://imagizer.imageshack.com/img921/6987/ex1nMl.png'

const JobForYou = () => (
  <div className="job-for-you">
    <div className="container">
        <h1>We have a Job for you</h1>
        <img src={pic} alt="antcolony we have job for you" />
    </div>
  </div>
)

export default JobForYou;