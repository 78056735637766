const Ismir = '//imagizer.imageshack.com/img922/6329/iAl48U.png'
const PortraitIsmir = '//imagizer.imageshack.com/img922/2710/PdIEb5.png'
const Kuna = '//imagizer.imageshack.com/img924/5046/a0Ud9k.png'
const PortraitKuna = '//imagizer.imageshack.com/img922/7643/vHy6y9.png'
const TarikM = '//imagizer.imageshack.com/img923/3597/4UgAac.png'
const PortraitTarikM = '//imagizer.imageshack.com/img923/7973/ZyshY3.png'
const Ena = '//imagizer.imageshack.com/img922/5297/3Q16sU.png'
const PortraitEna = '//imagizer.imageshack.com/img922/4139/8JNXol.png'
const Faruk = '//imagizer.imageshack.com/img921/7514/BknjV9.png'
const PortraitFaruk = '//imagizer.imageshack.com/img923/1748/TLjoQ6.png'
const Elvin = '//imagizer.imageshack.com/img922/5880/o4EGtM.png'
const PortraitElvin = '//imagizer.imageshack.com/img921/1227/J5RKTH.png'
const Edhem = '//imagizer.imageshack.com/img923/3243/eW0uFK.png'
const PortraitEdhem = '//imagizer.imageshack.com/img921/3058/4cXj2G.png'
const Mahir = '//imagizer.imageshack.com/img922/8476/VtQRnT.png'
const PortraitMahir = '//imagizer.imageshack.com/img921/5269/xhO5Uc.png'
const Alen = '//imagizer.imageshack.com/img924/4678/9JobMO.png'
const PortraitAlen = '//imagizer.imageshack.com/img921/6033/giYEzd.png'
const Hani = '//imagizer.imageshack.com/img923/9968/REvtla.png'
const PortraitHani = '//imagizer.imageshack.com/img921/2131/5hiBY0.png'
const Slaven = '//imagizer.imageshack.com/img923/8335/Pt96ze.png'
const PortraitSlaven = '//imagizer.imageshack.com/img922/3227/lQu8Og.png'
const Amar = '//imagizer.imageshack.com/img923/5364/bZmB49.png'
const PortraitAmar = '//imagizer.imageshack.com/img924/3749/izq0Pd.png'
const Zlata = '//imagizer.imageshack.com/img922/6805/5AVU30.png'
const PortraitZlata = '//imagizer.imageshack.com/img922/9950/TaLF8J.png'
const Haris = '//imagizer.imageshack.com/img924/4185/epZnhP.png'
const PortraitHaris = '//imagizer.imageshack.com/img921/4546/xS50nE.png'
const TarikP = '//imagizer.imageshack.com/img921/1360/4g5h2d.png'
const PortraitTarikP = '//imagizer.imageshack.com/img921/1334/ozlCQ3.png'
const Adi = '//imagizer.imageshack.com/img924/8738/mCXLxW.png'
const PortraitAdi = '//imagizer.imageshack.com/img924/5350/dyX1l5.png'
const Kenan = '//imagizer.imageshack.com/img921/6224/suG80d.png'
const PortraitKenan = '//imagizer.imageshack.com/img921/5526/RlNi2K.png'
const Belmin = '//imagizer.imageshack.com/img922/2541/T7Y8Lw.png'
const PortraitBelmin = '//imagizer.imageshack.com/img923/9981/Pea3HE.png'
const Ivica = '//imagizer.imageshack.com/img921/2461/BQD6FF.png'
const PortraitIvica = '//imagizer.imageshack.com/img922/8400/BmUGQH.png'
const Armin = '//imagizer.imageshack.com/img921/403/F1B6Nv.png'
const PortraitArmin = '//imagizer.imageshack.com/img921/403/F1B6Nv.png'
const Edin = '//imagizer.imageshack.com/img922/7114/9WhYDr.png'
const PortraitEdin = '//imagizer.imageshack.com/img922/7114/9WhYDr.png'
const BenjaminV2 = '//imagizer.imageshack.com/v2/803x803q90/r/922/ACxT2Y.png'
const PortraitBenjaminV2 = '//imagizer.imageshack.com/v2/803x803q90/r/922/ACxT2Y.png'
// const NEWANT = 'https://imagizer.imageshack.com/img923/8665/S8rIhU.png'
const Nazif = 'https://imagizer.imageshack.com/img924/7665/wfOO3u.png';

const Adis = '//imagizer.imageshack.com/img921/6055/Ewjgbq.png';
const PortraitAdis = '//imagizer.imageshack.com/img921/6055/Ewjgbq.png';

export const ants = [
  {
    ant: Ismir,
    portrait: PortraitIsmir,
    about: 'THE GODFATHER',
    name: 'Ismir',
    text: `Always ready to talk to you and make you an \noffer you can't refuse.`,
  },
  {
    ant: Ena,
    portrait: PortraitEna,
    about: 'UI/UX',
    name: 'Ena',
    text: "Be nice and you’ll get candy.\nKeep it at being nice.",
  },
  {
    ant: Kuna,
    portrait: PortraitKuna,
    about: 'FRONTEND TALENT',
    name: 'Kuna',
    text: "I'm a fun guy. haHAA",
  },
  {
    ant: TarikM,
    portrait: PortraitTarikM,
    about: 'Éminence grise',
    name: 'Tarik M.',
    text: "Boy! Boy. Boy ... (sigh)\nBOY! BOOOOOOYYYY!!!!!",
  },
  {
    ant: Faruk,
    portrait: PortraitFaruk,
    about: 'edge case',
    name: 'Faruk',
    text: "Well, here I am.\nWhat are your other two wishes?",
  },
  {
    ant: Elvin,
    portrait: PortraitElvin,
    about: "LOVES GINGER",
    name: 'Elvin',
    text: "If he had to decide what makes him happier,\nginger or coding, it would be a tough choice.",
  },
  {
    ant: Edhem,
    portrait: PortraitEdhem,
    about: "IOS FAN",
    name: 'Edhem',
    text: "Slayer of Stupid, Incompetent and\nDisappointing bugs but always keeps it cool\n and chill.",
  },
  {
    ant: Mahir,
    portrait: PortraitMahir,
    about: "THE JOKER",
    name: "Mahir",
    text: "Young, good looking and funny.",
  },
  {
    ant: Alen,
    portrait: PortraitAlen,
    about: "MR. HANTSOME",
    name: "Alen",
    text: "Terminator is soft compared to me.",
  },
  {
    ant: Hani,
    portrait: PortraitHani,
    about: 'PAPA ANT',
    name: 'Hani',
    text: "I consider myself Agile Evangelista.",
  },
  {
    ant: Slaven,
    portrait: PortraitSlaven,
    about: 'ADVENTURE GUY',
    name: "Slaven",
    text: "I make Jessica Simpson look like a \nrocket scientist.",
  },
  {
    ant: Amar,
    portrait: PortraitAmar,
    about: 'MR. PM',
    name: 'Amar',
    text: "Nani?",
  },
  {
    ant: Zlata,
    portrait: PortraitZlata,
    about: 'MOBILE WIZARD',
    name: 'Zlata',
    text: "Working as a dev till my Hogwarts \nletter arrives.",
  },
  {
    ant: Haris,
    portrait: PortraitHaris,
    about: 'BEARDO',
    name: 'Haris',
    text: "Looking grumpy at the code until it fixes \nitself. (Works every time)",
  },
  {
    ant: TarikP,
    portrait: PortraitTarikP,
    about: 'ENJOYING THE SILENCE',
    name: 'Tarik P.',
    text: "I believe the quieter you become,  the more \nyou can hear.",
  },
  {
    ant: Adi,
    portrait: PortraitAdi,
    about: 'YOUNG & DANGEROUS',
    name: "Adi",
    text: "Tvrd je orah voćka čudnovata, al' je čekić sprava....",
  },
  {
    ant: Kenan,
    portrait: PortraitKenan,
    about: 'THE NICE GUY',
    name: 'Kenan',
    text: "Dental technician and programmer. \nCan fix couple of bugs and your denture.",
  },
  {
    ant: Belmin,
    portrait: PortraitBelmin,
    about: 'LOVES HIS SPACE',
    name: 'Belmin',
    text: "To go forward, you must backup.",
  },
  {
    ant: Ivica,
    portrait: PortraitIvica,
    about: 'SENPAI',
    name: 'Ivica',
    text: "Meme enthusiast, music junkie. ",
  },
  {
    ant: Armin,
    portrait: PortraitArmin,
    about: 'Cat owner No. 3',
    name: 'Armin',
    text: "Code never lies, comments sometimes do. ",
  },
  {
    ant: BenjaminV2,
    portrait: PortraitBenjaminV2,
    about: `The riddler`,
    name: 'Benjamin',
    text: "Even the hardest puzzles have a solution. ",
  },
  {
    ant: Nazif,
    portrait: Nazif,
    about: `Friendly guy `,
    name: 'Nazif',
    text: "Comfortable adjusting to any situation and don’t get flustered easily when faced with unexpected challenges. ",
  },
  {
    ant: Edin,
    portrait: PortraitEdin,
    about: `Rockin' in the free world`,
    name: 'Edin',
    text: "If You're Going Through Hell, Keep Going. ",
  },
  {
    ant: Adis,
    portrait: PortraitAdis,
    about: `Mobile app specialist`,
    name: 'Adis',
    text: "Hard work always pays out. ",
  },
  // {
  //   img: NEWANT,
  //   about: 'TAKE YOUR OPOURTUNITY',
  //   name: 'This could be you',
  //   text: "Take a look at our open positions or just go ahead and send us your CV. We are always interested in amazing individuals",
  // },
]
