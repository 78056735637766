import React from 'react'
import ProjectItem from './ProjectItem'
import {projects} from './projects'
import {casestudies} from './casestudies'

const otherClients = 'https://imageshack.com/a/img922/9943/VIqzSK.png'
const jumpToTop = 'https://imagizer.imageshack.com/img922/7472/Z0KYeh.png'

const Projects = () =>{
    const scrollToTop = () =>{
        window.scroll({top: 0, left: 0, behavior: 'auto' })
      }

    return (
        <div className="projects">
            <div className="container projects-header">
                <h1 className="our-other-clients-header">Clients that have trusted us</h1>
            </div>
            <div className="container our-other-clients py-1">
                <img src={otherClients} id='otherClientsMobile' alt='Our other clients' />
            </div>

            <div className="container projects-header">
                <h1>The results of our work</h1>
                <p >Many projects have entered our Colony. Here you will find <br />some of our work showcased. </p>
            </div>
            <div >
                {projects.map(project => (
                        <ProjectItem className="project pb-md-5"
                        key={project.mainTitle}
                        firstTitle={project.firstTitle}
                        firstSubtitle={project.firstSubtitle}
                        firstText={project.firstText}
                        mainTitle={project.mainTitle}
                        secondTitle={project.secondTitle}
                        secondSubtitle={project.secondSubtitle}
                        secondText={project.secondText}
                        firstImage={project.firstImage}
                        secondImage={project.secondImage}
                        textOrientation={project.textOrientation}
                         />
                    ))}
            </div>
            <div className="container projects-header pb-5">
                <h1 className="case-studies-header">Our case studies</h1>
            </div>
            <div >
                {casestudies.map(project => (
                        <ProjectItem className="project  pb-md-5"
                        key={project.mainTitle}
                        firstTitle={project.firstTitle}
                        firstSubtitle={project.firstSubtitle}
                        firstText={project.firstText}
                        mainTitle={project.mainTitle}
                        secondTitle={project.secondTitle}
                        secondSubtitle={project.secondSubtitle}
                        secondText={project.secondText}
                        firstImage={project.firstImage}
                        secondImage={project.secondImage}
                        textOrientation={project.textOrientation}
                         />
                    ))}
            </div>

            <div className="container jump-to-top">
                <div className="jump-to-top--container">
                    <img src={jumpToTop} alt="jump to top "  onClick={scrollToTop}/>
                    <h5>JUMP TO TOP</h5>
                </div>
            </div>
        </div>

    )
}

export default Projects;
