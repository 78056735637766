import React from 'react';
import Fade from 'react-reveal/Fade';

const Ant = (props) => (
  <Fade>
    <div className="ant col-12 col-sm-4 ">
      <div className='ant-img-container' style={{height: `${props.height}`, width: `${props.height}`}}>
        <img src={props.ant} className='ant-icon' alt="ant" />
        <img src={props.portrait} className='ant-portrait'  alt='portrait'/>
      </div>
      <h5>{props.about}</h5>
      <h4>{props.name}</h4>
      <p>{props.text}</p>
    </div>
  </Fade>
)

export default Ant;