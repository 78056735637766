import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import routeConstants from '../routes/routeConstants';
import NavItem from '../Navbar/NavItem/NavItem';
import { Link } from 'react-router-dom';


// footer social links pictures
import facebook from '../Images/Icons/facebook-logo-button.svg'
import instagram from '../Images/Icons/instagram-3.svg';
import linked_in from '../Images/Icons/linkedin-2.svg';
import dribble from '../Images/Icons/dribble-logo-button-2.svg';

//  footer pictures
const footerhome = 'https://imagizer.imageshack.com/img924/3923/qJcmUU.gif';
const footerteam = 'https://imagizer.imageshack.com/img922/3997/EtoOds.png';
const footerengagementmodels = 'https://imagizer.imageshack.com/img921/1686/PwCqUF.gif';

// footer h1 header
const headerHome = "Enter the world of everything possible"
const headerTeam = "Come to the Colony, we have cookies"
const headerEngagementModels = "Let’s talk business"
const headerTestimonials ="Our experts are ready and at your service"
// footer text
const textHome = "It is dangerous to walk alone, take us with you."
const textTeam ="Have an interesting personality, skill set and a will to learn, then send us an email to"
const textEngagementModels = "For all business inquiries send an e-mail to"
const textTestimonials = "For all business inquiries send an e-mail to"
// footer contact email
const contactIsmir = 'ismir@antcolony.io'

const Footer = (props) => {
    const [footerPicture, setFooterPicture] = useState(footerhome)
    const [headerText, setHeaderText] = useState(headerHome)
    const[text, setText] = useState(textHome)
    const[contact, setContact] = useState('info@antcolony.io')

    const {
        location: {
          pathname,
        }
      } = props;



    useEffect(() => {
      if (["/team", "/jobs"].includes(pathname) ) {
        setHeaderText(headerTeam)
        setText(textTeam)
        setFooterPicture(footerteam)
        setContact('info@antcolony.io')
      }else if(["/how-we-work", "/projects", "/gallery"].includes(pathname)) {
        setHeaderText(headerEngagementModels)
        setText(textEngagementModels)
        setFooterPicture(footerengagementmodels)
        setContact(contactIsmir)
      }else if((pathname).includes("/testimonials")){
        setHeaderText(headerTestimonials)
        setText(textTestimonials)
        setFooterPicture(footerengagementmodels)
        setContact(contactIsmir)
      }else {
        setHeaderText(headerHome)
        setText(textHome)
        setFooterPicture(footerhome)
        setContact('')
      }
    }, [pathname]);

    return(
        <div className="footer-main">
            <div className="container pb-0">
                <h2>{headerText}</h2>
                <p>{text}</p>
                <Link to="/contact">
                  <p className="p-contact"><strong>{contact}</strong></p>
                </Link>
                <img src={footerPicture} alt="footer antcolony" className="col-md-4  footer-img" />
                <hr className="footer-border-first"></hr>
                <div className="container">
                  <ul className="footer-pagelinks">
                          {routeConstants.map(route => (
                          <NavItem
                              key={route.routeIndex}
                              route={route.route}
                              title={route.title}
                              name={route.name}
                              pathname={pathname}
                          />
                          ))}
                      </ul>
                </div>
                <div className="container footer-sociallinks">
                    <a  target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/antcolonycompany">
                         <img src={facebook} alt="facebook" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/antcolony_sarajevo">
                      <img src={instagram} alt="instagram" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/10947248">
                      <img src={linked_in} alt="linked_in" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://dribbble.com/playbook">
                      <img src={dribble} alt="dribble" />
                    </a>
                </div>
                <hr className="footer-border-second"></hr>
                <p className="footer-info ">Skenderpašina 1, 7100 Sarajevo BiH <br /> E-mail: info@antcolony.io &ensp;  Call: +387 61 552 940</p>
                <br />
                <p className="footer-copyright">© 2019. All rights reserved by AntColony</p>
            </div>
        </div>
    )
}

export default withRouter(Footer);