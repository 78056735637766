import React, { useEffect, useRef, useState } from 'react';
import { ants } from './Ant/ants'
import Ant from './Ant';
import OurTradition from './OurTradition/OurTradition'
import useWindowSize from "../utils/useWindowSize";
const jumpToTop = 'https://imagizer.imageshack.com/img922/7472/Z0KYeh.png'

const Team = () => {
  const size = useWindowSize();

  const [antImgHeight, setAntImgHeight] = useState("");

  const antImg = useRef(null);
  const scrollToTop = () =>{
    window.scroll({top: 0, left: 0, behavior: 'auto' })
  }

  useEffect(() => {
    if (antImg.current) {
      setAntImgHeight(`${antImg.current.getBoundingClientRect().width}px`)
    }
  }, [size])

  return (
    <div className="team">
      <OurTradition />
      <div className="container ants">
        <h1>Meet our Colony members</h1>
        <div className="ants-row">
          {ants.map(ant => (
              <Ant height={antImgHeight}  key={ant.name} about={ant.about} name={ant.name} ant={ant.ant}  text={ant.text}  portrait={ant.portrait}/>
          ))}
          <div className="hidden-ant col-12 col-sm-4 ">
            <div className='ant-img-container' ref={antImg} style={{ height: `${antImgHeight}`, width: "50%"}}>
              <img src={jumpToTop} className='ant' alt="ant" />
            </div>
          </div>
        </div>
      </div>
      <div className="container jump-to-top">
          <div className="jump-to-top--container">
              <img src={jumpToTop} alt="jump to top "  onClick={scrollToTop}/>
              <h5>JUMP TO TOP</h5>
          </div>
      </div>
    </div>
  )
}


export default Team;

