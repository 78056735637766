import React from 'react';
import { Link } from 'react-router-dom';

const NavItem = ({ name, title, route, closeNavbar, delay, navTheme, pathname }) => (
  <li className={`nav-item ${pathname === route? name : "" }`} style={{animationDelay: delay}}>
    <Link
      className={`nav-link ${navTheme ? "black-link" : ""}`}
      to={route}
      title={title}
      onClick={closeNavbar}
    >
      {title}
    </Link>
  </li>
);

export default NavItem;