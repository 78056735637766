import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import routeConstants from '../routes/routeConstants';
import NavItem from './NavItem/NavItem';

const WhiteLogo = 'https://imagizer.imageshack.com/img922/1183/JKkSpy.png';
const BlackLogo = 'https://imagizer.imageshack.com/img924/341/UPL7od.png'


const SiteNavbar = (props) => {
  const [collapsedNavbar, setCollapsedNavbar] = useState(true);
  const [filledNavbar, setFilledNavbar] = useState(false);
  const [navTheme, setNavTheme] = useState(false)
  const [blackButton, setBlackButton] = useState(false)

  const toggleNavbar = () => {
    setCollapsedNavbar(!collapsedNavbar);
  };

  const closeNavbar = () => {
    setCollapsedNavbar(true);
  };

  const {
    location: {
      pathname,
    }
  } = props;

  const getImageTheme = () => {
    if(!navTheme) return WhiteLogo;
    if(filledNavbar) return WhiteLogo;
    return BlackLogo;
  }


  useEffect(() => {
    if (["/team", "/jobs", "/contact", "/testimonials", "/projects", "/gallery"].includes(pathname)) {
      setNavTheme(true)
      setBlackButton(true)
    }
    else{
      if (!filledNavbar) setNavTheme(false)
      setNavTheme(false)
      setBlackButton(false)
    }
  }, [filledNavbar, pathname]);

  useEffect(() => {
    const handleNavbarScrolled = () => {
      if ((window.pageYOffset > 60)) {
        setFilledNavbar(true);
      } else {
        setFilledNavbar(false);
      }
    }
    window.addEventListener('scroll', handleNavbarScrolled);

    return () => {
      window.removeEventListener('scroll', handleNavbarScrolled);
    };
  }, [filledNavbar]);
  return (
    <nav className={`navbar navbar-expand-lg ${filledNavbar ? 'filled-nav' : ''}`} >
      <div className="container">
        <Link to='/' className="navbar-brand">
          <img src={getImageTheme()} alt="ANT" className="navbar-title--logo" />
          <p className={`navbar-title--name ${navTheme ? "black-logo-text" : ""}`}><strong className="ant-strong">ANT</strong>COLONY</p>
        </Link>
        <button
          className={`navbar-button ${collapsedNavbar ? '' : 'navbar-open'} ${blackButton? collapsedNavbar? filledNavbar? '' : 'black-button' : '' : ''} `}
          type="button"
          onClick={toggleNavbar}
          aria-label="Toggle navigation">
          <div className="line line-top" />
          <div className="line line-middle" />
          <div className="line line-bottom" />
        </button>
        <div className={`collapse navbar-collapse ${collapsedNavbar ? '' : 'show'}`}>
          <ul className="navbar-nav pt-2">
            {routeConstants.map(route => (
              <NavItem
                key={route.routeIndex}
                route={route.route}
                title={route.title}
                closeNavbar={closeNavbar}
                delay={`${route.routeIndex * 0.1}s`}
                navTheme={navTheme}
                name={route.name}
                pathname={pathname}
              />
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default withRouter(SiteNavbar);