import React from 'react';
import LandingHeader from './LandingHeader/LandingHeader'
import OurAim from './OurAim/OurAim'
import Diversity from './Diversity/Diversity'
import Workflow from './Workflow/Workflow'
import StartupIdea from './StartupIdea/StartupIdea'
import Fun from './Fun/Fun'
import JoinUs from './JoinUs/JoinUs'

const jumpToTop = 'https://imagizer.imageshack.com/img922/7472/Z0KYeh.png'


const Home = () => {
  const scrollToTop = () =>{
    window.scroll({top: 0, left: 0, behavior: 'auto' })
  }

  return(
    <div className="home">
      <LandingHeader />
      <OurAim />
      <Diversity />
      <Workflow />
      <StartupIdea />
      <Fun />
      <JoinUs />
       <div className="container jump-to-top">
          <div className="jump-to-top--container">
              <img src={jumpToTop} alt="jump to top "  onClick={scrollToTop}/>
              <h5>JUMP TO TOP</h5>
          </div>
      </div>
    </div>
)
}
export default Home;