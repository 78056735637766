import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import {Icon} from 'antd';


const OurAim = () => (
 <div className="our-aim">

    <div className="container ">
        <div  className="d-flex our-aim-contact">
            <p className="our-aim-contact--text pl-0">Have a start-up idea? Contact us</p>
            <Link to="/contact">
                <button className="our-aim-contact--button"><Icon type="arrow-right" /></button>
            </Link>
        </div>
        <h1>Product design to lift up your customers and your business</h1>
        <p className="p-header">Our aim is to grow your product as if it were our own. With passion and dedication,<br/> we strive to make your product better and more successful. </p>
    </div>

    <div className="container our-aim-container--picture">
        <Fade left>
            <div className="our-aim-container--first-picture mr-md-2" ></div>
        </Fade>
        <Fade right>
            <div className="our-aim-container--second-picture ml-md-2" ></div>
        </Fade>
    </div>

    <div className="container our-aim-card">
    <div className="row">
        <div className="our-aim-container--first-picture" id="first-picture"></div>
        <div className="our-aim-container--second-picture" id="second-picture"></div>
        <div className="col-12 col-md-6 our-aim-card-row" id="dedication">
           <div className="d-flex aim-header">
                <div className="Oval">1</div>
                <h5>DEDICATION</h5>
           </div>
           <h2>Using time wisely</h2>
           <p className="col-md-10 pl-0 ">We are fully dedicated to transform your idea into a full fledged product or to transform your existing product into a more successful story.</p>
        </div>
        <div className="col-12 col-md-6 our-aim-card-row pl-md-5" id="teamwork">
            <div className="d-flex aim-header">
                <div className="Oval">2</div>
                <h5>TEAMWORK</h5>
            </div>
            <h2>Powers combined</h2>
            <p  className="col-md-10 pl-0 " >Our Colony consists of experts in various fields of software development, management, sales, consulting, tailored for your needs and requirements.</p>
        </div>
     </div>
    </div>
 </div>

)

export default OurAim;
