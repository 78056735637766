import React from 'react';

import { useState, useRef } from 'react';

const pic = 'https://imagizer.imageshack.com/img924/7561/4yrZYS.png'

const JobPosition = (props) => {
    const [fullText, setFullText] = useState(false)
    const textHeightRef = useRef(null)
    const clickCollapse = () => {
        setFullText(!fullText)
    }

    const getTextHeight = () => {
      if (textHeightRef.current) {
        const elementHeight = textHeightRef.current.scrollHeight;
        return `${elementHeight}px`;
      }
    }

    return(
    <div className={`${props.className} col-12 `}>
      <div className="job-position-header">
        <div>
            <img src={pic} alt="job position illustration AntColony work chance" />
        </div>
        <div className="job-position-header--titles">
            <h5 >{props.subtitle}</h5>
            <h3>{props.positonTitle}</h3>
        </div>

      </div>
      <div className="job-position-text ">
        <div
          className={`text-container ${fullText ? 'full-text' : '' }`}
          style={{ height: `${fullText ? getTextHeight() : '150px'}` }}
          ref={textHeightRef}
        >
            <p>{props.text}</p>
        </div>
        <div className="button-container" onClick={clickCollapse}>
            <button className={`${fullText? 'read-less--button' : 'read-more--button'}`} >{fullText ? 'READ LESS' :  'READ MORE'}</button>
        </div>
      </div>    
    </div>  
    
)}

export default JobPosition;