import React from 'react';

const door = 'https://imagizer.imageshack.com/img924/9677/c6ABuI.png'
const gif = 'https://imagizer.imageshack.com/img921/9428/bIOluu.gif'

const OurEngagementModel= () => {
  return (

    <div className="our-engagement-model">
      <div className="container-fluid">
          <h1 className="pb-md-4">
               How we work?
          </h1>
          <p className="p-header">
          Research · Strategy · Design · Development · Delivery · Growth
          </p>
          <div className="wrapper ">
            <img src={gif} alt="matrix" style={{width: "43%"}}></img>
            <div className="img-container">
              <img src={door} alt="antcolony ulica softverdziluk" />
            </div>
          </div>
      </div>
    </div>
  )
}


export default OurEngagementModel;