import React from 'react';
import { Link } from 'react-router-dom';
import Tilt from 'react-tilt'

const cup = 'https://imagizer.imageshack.com/img923/4848/CwcvSi.png';
const ant_sitting = 'https://imagizer.imageshack.com/img922/2578/3Ol1Rf.png';
const rocher = 'https://imagizer.imageshack.com/img924/5867/f8toNE.png';

const Diversity = () => (
  <div className="diversity">
    <div className="container ">
      <h1>We're strategic digital partners to innovative companies</h1>
      <p className="p-header">The internet’s a tough neighborhood. Our Ants know how to tame it. <br />Let us shape your business concept to be noticed. So just sit back and relax. </p>

      <div className="rocher-wrapper">
        <div className="box bounce">
          <img src={rocher} alt="rocher bounce" />
        </div>
        <div className="image-container">
        <img src={cup} alt="pic" />
        </div>
      </div>
      <div className="one-row" >
          <div className="col-12 col-md-6 px-0">
              <Tilt className="Tilt" options={{ max : 35 , scale: 1}} >
                  <div className="Tilt-inner">
                    <img src={ant_sitting} alt="pic" />
                  </div>
              </Tilt>
          </div>
          <div className="col-12 col-md-6 diversity-row px-0">
              <div className="d-flex">
                  <div className="Oval">3</div>
                  <h5>KEEPING AN OPEN MIND</h5>
              </div>
              <h2>Seeking assistance?</h2>
              <p >Feel free to contact us about your ideas or a business proposal. Our experts will evaluate and give you honest feedback, every idea can be transformed into something amazing. Don't be shy!</p>
              <Link to="/contact">
                <button className="diversity-button">EMAIL US</button>
              </Link>
          </div>
      </div>
    </div>
  </div>
)

export default Diversity;