export const pictures = [
{
    img: 'https://imagizer.imageshack.com/img923/8357/eTfYHN.jpg'
},{
    img: 'https://imagizer.imageshack.com/img921/7176/kVvKCs.png'
},{
    img: 'https://imagizer.imageshack.com/img921/4684/qFPpwM.png'
},{
    img: 'https://imagizer.imageshack.com/img923/8398/j9le6q.png'
},{
    img: 'https://imagizer.imageshack.com/img922/9848/YdsuDm.jpg'
},{
    img: 'https://imagizer.imageshack.com/img923/9852/9XNkkz.png'
},{
    img: 'https://imagizer.imageshack.com/img923/7299/G6aeGE.png'
},{
    img: 'https://imagizer.imageshack.com/img922/3676/vMrprG.png'
},{
    img: 'https://imagizer.imageshack.com/img924/6016/L9Jbrp.png'
},{
    img: 'https://imagizer.imageshack.com/img921/8610/PxA5Sk.png'
},{
    img: 'https://imagizer.imageshack.com/img924/6546/lHp1KA.png'
},{
    img: 'https://imagizer.imageshack.com/img922/941/YZWYBr.png'
},{
    img: 'https://imagizer.imageshack.com/img922/6411/YAeyha.png'
},{
    img: 'https://imagizer.imageshack.com/img921/1666/SvJ1Nn.png'
},{
    img: 'https://imagizer.imageshack.com/img922/341/z7Rdzm.png'
},{
    img: 'https://imagizer.imageshack.com/img922/2907/lwgjFA.png'
},{
    img: 'https://imagizer.imageshack.com/img921/2343/QPrluJ.png'
},{
    img: 'https://imagizer.imageshack.com/img923/1893/a11tbf.png'
},{
    img: 'https://imagizer.imageshack.com/img921/2871/BfHv7g.png'
},{
    img: 'https://imagizer.imageshack.com/img924/3758/3aa8Ps.png'
},{
    img: 'https://imagizer.imageshack.com/img923/7332/Xbwqmg.png'
},{
    img: 'https://imagizer.imageshack.com/img923/7407/DyJwWx.png'
},{
    img: 'https://imagizer.imageshack.com/img921/9342/TVJUen.png'
},{
    img: 'https://imagizer.imageshack.com/img921/493/XhESXh.png'
}
]