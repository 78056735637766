import React from 'react';

const pic = 'https://imagizer.imageshack.com/img921/3814/FwZ9j8.png'

const SetOfSkills= () => (
  <div className="set-of-skills">
    <div className="container">
        <h1>
             Our set of skills
        </h1>
        <img src={pic} alt="antcolony skills technologies agile" />
    </div>
  </div>
)

export default SetOfSkills;