import React, {useState} from 'react'
import {Icon} from 'antd';
import axios from 'axios';

const jumpToTop = 'https://imagizer.imageshack.com/img922/7472/Z0KYeh.png'

const Contact = () =>{
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        text: ''
    })

    const [buttonLoading, setButtonLoading] = useState("notClicked")

    const { name, email, phone, subject, text} = formData

    const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        setButtonLoading("loading")

        var data = {
           "name": `New request from: ${name} | ${subject}`,
           "email": `${email}`,
           "message": `User with following information \n\ e-mail: ${email} \n\ phone: ${phone} \n\ has sent following message: \n\ ${text}`,
         };

         axios.post('https://ant-colony-mailing.herokuapp.com/submit', data)
         .then(function (response) {
            setButtonLoading("received");
          })
          .catch(function (error) {
            setButtonLoading("received");
          });
      };

    const getButtonStateClassName = () => {
        if (buttonLoading === "loading") {
            return "active"
        }
        if (buttonLoading ==="received") {
            return "finished"
        }
        return ""
    }

    const scrollToTop = () =>{
      window.scroll({top: 0, left: 0, behavior: 'auto' })
    }

    return(
    <div className="contact">
     <div className="container">
     <div className="contact-header px-4 pb-5">
            <h1 className="mb-2">Talk to us!</h1>
            <p>If you would like to work with us, apply for a job, have an interesting startup idea <br />or you just have a question, Contact us!</p>
        </div>
        <form className='form col-12 col-md-7 mx-md-auto' onSubmit={e => onSubmit(e)}>
            <div className='form-group'>
                <div className=" one-row">
                    <h6 className="col-12 col-md-2 px-0 px-md-3 pb-2 pb-md-0">Name</h6>
                    <input
                        className="input-area col-12 col-md-9"
                        type='name'
                        placeholder='Enter name'
                        name='name'
                        value={name}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>
            </div>
            <div className='form-group'>
                <div className=" one-row">
                    <h6 className="col-12 col-md-2 px-0 px-md-3 pb-2 pb-md-0">E-mail</h6>
                    <input
                        className="input-area col-12 col-md-9"
                        type='email'
                        placeholder='E-mail'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        required
                    />
                </div>
            </div>
            <div className='form-group'>
                <div className=" one-row">
                    <h6 className="col-12 col-md-2 px-0 px-md-3 pb-2 pb-md-0">Phone</h6>
                    <input
                        className="input-area col-12 col-md-9"
                        type='text'
                        placeholder='Enter phone number'
                        name='phone'
                        value={phone}
                        onChange={e => onChange(e)}
                    />
                </div>
            </div>
            <div className='form-group'>
                <div className=" one-row">
                    <h6 className="col-12 col-md-2 px-0 px-md-3 pb-2 pb-md-0">Subject</h6>
                    <input
                        className="input-area col-12 col-md-9"
                        type='text'
                        placeholder='Subject'
                        name='subject'
                        value={subject}
                        onChange={e => onChange(e)}
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="">
                    <textarea
                        className="col-12"
                        type='text'
                        placeholder='Tell us more...'
                        name='text'
                        value={text}
                        onChange={e => onChange(e)}
                        rows="7"
                    >
                    ></textarea>
                </div>
            </div>
            <div className="one-row">
                <h6 className="px-0 px-md-3 pb-2 pb-md-0">We’ll get back to you soon.</h6>
                <button type='submit' className={`send-button ${getButtonStateClassName()}`} value='Send' >
                    <span className="submit">Send</span>
                    <span className="loading" ><Icon type="sync" spin  style={{ fontSize: '28px', color: 'red' }}/> </span>
                    <span className="check" ><Icon type="check-circle"   style={{ fontSize: '30px', color: 'red' }}/></span>
                </button>
            </div>
      </form>
     </div>
     <div className="container jump-to-top">
          <div className="jump-to-top--container">
              <img src={jumpToTop} alt="jump to top "  onClick={scrollToTop}/>
              <h5>JUMP TO TOP</h5>
          </div>
      </div>
    </div>
    )
}

export default Contact;
