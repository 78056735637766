import React from 'react';
import { Link } from 'react-router-dom';

const pic1 = 'https://imagizer.imageshack.com/img922/8643/WhlwtM.png';
const rocket = 'https://imagizer.imageshack.com/img921/1073/px9XBK.png';

const StartupIdea = () => (
  <div className="startup-idea">
    <div className="container">
      <h1>Have a startup idea? Tell us more</h1>
      <p className="p-header">Feel free to ask for tips and guidance to validate and skyrocket your startup<br /> idea into reality. </p>
      <Link to="/contact">
        <button className="startup-idea-button">LET US KNOW</button>
      </Link>
      <div className="rocket-wrapper">
        <div className="box bounce">
          <img src={rocket} alt="rocket bounce" />
        </div>
        <div className="image-container">
           <img src={pic1} alt="pic" />
        </div>
      </div>
    </div>
  </div>
)

export default StartupIdea;