import React from 'react';

const pic1 = 'https://imagizer.imageshack.com/img922/1417/SAJfvc.png';
const pic2 = 'https://imagizer.imageshack.com/img923/80/mFCi62.png';
const pic3 = 'https://imagizer.imageshack.com/img921/7666/KqTejn.png';
const pic4 = 'https://imagizer.imageshack.com/img924/221/hD52xc.png';

const Workflow = () => {
    return(
    <div className="workflow">
        <div className="container">
            <h1>This is our workflow</h1>
            <p className="p-text" >A small glimpse of our way of handling things.</p>
        <div className="container px-0">
            <div className="one-row ">
                <div className="col-12 col-md-6 workflow-column">
                    <img src={pic1} alt="pic" />
                    <h2>Welcome to our Ant Kitchen</h2>
                    <p>Here we are cooking great products with perfect ingredients, mixing it in a fast paced agile environment, and we cook and improve our dishes over and over again.</p>
                </div>
                <div className="col-12 col-md-6 workflow-column">
                    <img src={pic2} alt="pic" />
                    <div className="d-flex workflow-row">
                        <div className="d-flex">
                            <div className="Oval">1</div>
                            <h5>PREPARE AND USE THE BEST INGREDIENTS</h5>
                        </div>
                        <p className="p-workflow">Our chefs are true experts, and using anything but the very best solutions and practices is out of the question. &ensp;</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 workflow-column">
                    <img src={pic3} alt="pic" />
                    <div className="d-flex workflow-row">
                        <div className="d-flex">
                            <div className="Oval">2</div>
                            <h5>GIVE IT TIME</h5>
                        </div>
                        <p>All good things need time, so before we deliver the final "dish" we make sure everything is perfect and ready, that’s why we always test our food.</p>
                    </div>
                </div>
                <div className="col-12 col-md-6 workflow-column">
                    <img src={pic4} alt="pic" />
                    <div className="d-flex workflow-row">
                        <div className="d-flex">
                            <div className="Oval">3</div>
                            <h5>ENJOY THE RESULT</h5>
                        </div>
                        <p>Voilà! Nothing short but what we promised, a perfect product, exactly what you deserve. </p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    )
}

export default Workflow;