import React from 'react';
import { Link } from 'react-router-dom';


const JoinUs = () => (
<div className="join-us">
  <div className="join-us--picture">
  <div className="col-12 col-md-6 join-us--col">
    <h2>Interested in joining our Colony?</h2>
    <p className="pr-md-5">Working at Ant Colony will not just shape you into a better professional, but you will be part of an ever growing family that will challenge you daily and nurture your skills. Take a look at our open positions, looking forward to meeting you!</p>
    <Link to="/jobs">
      <button className="join-us--button" >SEE JOBS</button>
    </Link>
  </div>
  </div>
  </div>
)

export default JoinUs;