import React from 'react';
import Tilt from 'react-tilt';

const pic = 'https://imagizer.imageshack.com/img923/6328/PVrE7Z.png'
const leaf = 'https://imagizer.imageshack.com/img923/7836/42rVgn.png'

const WelcomeToOurHome= () => (
  <div className="welcome-to-our-home">
    <div className="container">
        <h1>
             Welcome to our home
        </h1>
        <p className="p-header">
        Quality products above everything.
        </p>
    </div>


    <div className="leaf-wrapper">
        <div className="box bounce">

          <Tilt className="Tilt" options={{ max : 45, scale: 1.2 }} >
              <div className="Tilt-inner">
                <img src={leaf} alt="leaf bounce" />
              </div>
          </Tilt>
        </div>
        <div className="container image-container">
          <img src={pic} alt="antcolony company light coding" />
        </div>
      </div>
    <div className="container">
      <p>
      “Ant colonies offer an example of a system in which the component parts -- ants -- are fairly <br />simple and there is no hierarchical control, yet the whole colony performs complex, integrated behavior.”
      </p>
    </div>

  </div>
)

export default WelcomeToOurHome;