import React from 'react';
import { pictures } from './Items/Items';
import GalleryItem from './Items/index';
const jumpToTop = 'https://imagizer.imageshack.com/img922/7472/Z0KYeh.png'

const Gallery = () => {
    const scrollToTop = () =>{
        window.scroll({top: 0, left: 0, behavior: 'auto' })
      }

    return (
        <div className='gallery'>
            <div className='container'>
                <h1>Life inside Ant Colony</h1>
                <div className="items-row">
                    {pictures.map(pic => (
                        <GalleryItem  key={pic.img} img={pic.img} />
                    ))}
                </div>
            </div>
            <div className="container jump-to-top">
              <div className="jump-to-top--container">
                  <img src={jumpToTop} alt="jump to top "  onClick={scrollToTop}/>
                  <h5>JUMP TO TOP</h5>
              </div>
            </div>
        </div>
    )
}

export default Gallery;