const routeConstants = [
  {
    routeIndex: 0,
    title: 'Home',
    name: "home",
    route: '/',
  },
  {
    routeIndex: 1,
    title: 'Meet the team',
    name: "team",
    route: '/team',
  },
  {
    routeIndex: 2,
    title: 'How we work',
    name: "how-we-work",
    route: '/how-we-work',
  },
  {
    routeIndex: 3,
    title: 'Projects',
    name: "projects",
    route: '/projects',
  },
  // {
  //   routeIndex: 4,
  //   title: 'Testimonials',
  //   name: "testimonials",
  //   route: '/testimonials',
  // },
  {
    routeIndex: 4,
    title: 'Gallery',
    name: "gallery",
    route: '/gallery',
  },
  {
    routeIndex: 5,
    title: 'Contact',
    name: "contact",
    route: '/contact',
  },
  {
    routeIndex: 6,
    title: 'Jobs',
    name: "jobs",
    route: '/jobs',
  },
];

export default routeConstants;